<template>
  <van-form @submit="onSubmit">
    <van-field
      readonly
      clickable
      name="refundReason"
      :model-value="reason.value"
      label="退款原因"
      placeholder="点击选择"
      @click="reason.show = true"
    />
    <van-popup v-model:show="reason.show" position="bottom">
      <van-picker
        show-toolbar
        :columns="reason.option"
        @confirm="onConfirm"
        @cancel="reason.show = false"
      />
    </van-popup>
    <van-field
      v-model="refundAmount"
      type="number"
      name="refundAmount"
      label="退款金额"
      placeholder="请输入退款金额"
      disabled
      :rules="[{ required: true, message: '请填写' }]"
    />
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">
        提交
      </van-button>
    </div>
  </van-form>
  <van-cell title="温馨提示" />
  <p style="padding:15px;background:#fff;">
    <small>
      退款后，部分情况需要审核。退款款项将按原路退回到你的账户，如微信/支付宝等，可打开相应APP查看。退款前，请多沟通协商
      <!-- ，若退款中遇到问题或纠纷，请联系蜂动官方客服协助处理 -->
    </small>
  </p>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast, Dialog } from 'vant'

export default {
  setup () {
    const post = inject('post')
    const useRoute = inject('useRoute')
    const useRouter = inject('useRouter')
    const Cookies = inject('Cookies')
    const state = reactive({
      orderId: useRoute.query.orderId,
      storeId: Cookies.get('storeId'),
      order: {},
      refundMessage: '',
      refundAmount: 0,
      reason: {
        show: false,
        value: '不喜欢/不想要',
        option: ['不喜欢/不想要', '商品描述与实际不符', '假冒商品', '未发货', '其他']
      }
    })
    const onSubmit = (values) => {
      Dialog.confirm({
        title: '提示',
        message: '确定这么做吗？'
      }).then(() => {
        post('/store.get', {
          storeId: state.storeId
        }).then(res => {
          let httpUrl = '/order.applyRefund'
          if (res.data.storeMerchant) {
            if (res.data.storeMerchant.applymentState === 'FINISH') {
              httpUrl = '/refunds'
            }
          }
          values.orderId = state.orderId
          post(httpUrl, {
            amount: values.refundAmount,
            orderId: state.orderId,
            storeId: state.storeId,
            reason: values.refundReason,
            sponsor: 'BUYER'
          }).then(res => {
            if (res.code === 0) {
              Toast.success()
              useRouter.go(-1)
            } else {
              Toast(res.msg)
            }
          })
        })
      }).catch(() => {})
    }
    const onConfirm = (value) => {
      state.reason.value = value
      state.reason.show = false
    }
    const init = () => {
      post('/order.getRefundableAmount', {
        orderId: state.orderId
      }).then(res => {
        if (res.code === 0) {
          state.refundAmount = res.data.amount
        } else {
          Toast(res.msg)
        }
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onConfirm,
      onSubmit
    }
  }
}
</script>

<style scoped>
</style>
